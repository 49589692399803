<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            <b-col sm="4">
              <!-- Parameter -->

              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="GlobeIcon" size="14" />
                    <span class="align-middle ml-50">Observer</span>
                  </b-card-title>
                </b-card-header>
                <b-row class="pl-1 pr-1 mt-1">
                  <b-col>
                    <b-form-group label-cols="4" label-cols-lg="6" label-size="sm" label="Nama Observer" label-for="input-sm">
                      <b-form-input id="propinsi_code" v-model="propinsi_code" size="sm" placeholder="Input Nama" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <!--  button car1 -->
              <b-button v-if="status_entry == 'insert'" variant="primary" @click="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Tambah
              </b-button>
              <b-button v-if="status_entry == 'edit'" variant="primary" @click="update" class="mb-1 mb-sm-0 mr-0 mr-sm-1" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Update
              </b-button>
              <b-button variant="gradient-danger" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="reset" size="sm" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
                Batal
              </b-button>
            </b-col>

            <b-col sm="8">
              <!-- Parameter TABLE -->
              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="GlobeIcon" size="14" />
                    <span class="align-middle ml-50">Observer</span>
                  </b-card-title>
                </b-card-header>
                <b-row class="pl-1 pr-1 mt-1 mr-1 justify-content-end">
                  <!-- Table 1 -->
                  <div class="custom-search d-flex ">
                    <b-form-group>
                      <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" size="sm" />
                      </div>
                    </b-form-group>
                  </div>
                </b-row>

                <!-- table -->
                <vue-good-table
                  :columns="columns"
                  :rows="rows"
                  :rtl="direction"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'action'">
                      <span>
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                          </template>
                          <b-dropdown-item @click="onActionEdit(props.row.path)">
                            <feather-icon icon="Edit2Icon" class="mr-50" />
                            <span>Edit</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="onActionDelete(props.row.path)">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span>Hapus</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1" @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
                <!-- end table 1 -->
                <!-- table 2 -->
              </b-card>
              <!-- Action Buttons -->
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  VBTooltip,
  BCardHeader,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
  BForm,
  BButton,
  BButtonGroup,
  BFormGroup,
  BCollapse,
  BFormDatepicker,
  BFormInput,
  BTable,
  BAvatar,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import metadata from "@/api/metadata";
import helper from "@/api/helper";
export default {
  components: {
    BCard,
    BCardCode,
    VueGoodTable,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BButtonGroup,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    BTable,
    TrashIcon,
    Edit2Icon,
    BAvatar,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  data: function() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Balai",
          field: "balai",
        },
        {
          label: "Kode Propinsi",
          field: "propinsi_code",
        },
        {
          label: "Nama Propinsi",
          field: "propinsi_name",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      items: [],
      regions: "",
      regions_opt: [{ value: "", text: "Pilih Balai" }],
      region_filter: "",
      region_filter_opt: [
        { value: "", text: "Pilih Balai" },
        { value: "all", text: "Semua" },
      ],
      propinsi_code: "",
      propinsi_name: "",
      arr_data_propinsi: {},
      status_entry: "insert",
      arr_region: {},
      path: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    // this.$http.get("/good-table/basic").then((res) => {
    //   this.rows = res.data;
    //   console.log("ada rows : ",this.rows)
    // });
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  methods: {
    onActionEdit(val) {
      this.status_entry = "edit";
      const data = this.arr_data_propinsi[val];
      console.log("arr : ", this.arr_data_propinsi);
      console.log("valna : ", data);
      this.regions = data.balai_id;
      this.propinsi_code = data.propinsi_code;
      this.propinsi_name = data.propinsi_name;
      const path = val;
      this.path = path;
    },
    async onActionDelete(val) {
      console.log(val);
      const res = await axios.delete(helper.getBaseUrl() + val, helper.getConfig());
      // try{
      //   const res = await metadata.deletePropinsi(val)
      //   this.region_filter = val.substr(1,1)
      // }catch(e){

      // }
    },
    update() {
      // console.log("reg : ",this.regions)
      // return
      const bodyData = {
        "@type": "Propinsi",
        propinsi_name: this.propinsi_name,
        propinsi_code: this.propinsi_code,
        region_id: this.regions,
        region_description: this.arr_region[this.regions],
      };
      if (this.regions == "" || this.propinsi_name == "") {
        this.$swal({
          title: "Input Error!",
          text: "Silahkan Lengkapi data",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        metadata
          .patchPorpinsi(bodyData, this.path)
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$swal({
                title: "Update Sukses",
                text: "Propinsi Telah di update",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.propinsi_name = "";
              this.propinsi_code = "";
              this.listPropinsi(this.regions);
            }
          })
          .catch((error) => {
            this.$swal({
              title: "Input Error",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
      this.status_entry = "insert";
    },
    submit() {
      // console.log("reg : ",this.regions)
      // return
      const bodyData = {
        "@type": "Propinsi",
        propinsi_name: this.propinsi_name,
        propinsi_code: this.propinsi_code,
        region_id: this.regions,
        region_description: this.arr_region[this.regions],
      };
      if (this.regions == "" || this.propinsi_name == "") {
        this.$swal({
          title: "Input Error!",
          text: "Silahkan Lengkapi data",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        metadata
          .postPorpinsi(bodyData, this.regions)
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$swal({
                title: "Input Sukses",
                text: "Propinsi Telah di simpan",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.propinsi_name = "";
              this.propinsi_code = "";
              this.listPropinsi(this.regions);
            }
          })
          .catch((error) => {
            this.$swal({
              title: "Input Error",
              text: "Terjadi Kesalahan",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
      this.status_entry = "insert";
    },
    async optionRegion() {
      try {
        const { data } = await metadata.getRegionList();
        console.log(data);
        for (var i = 0; i < data.length; i++) {
          const dataRegion = {
            value: data[i].region_id,
            text: data[i].region_description,
          };
          this.regions_opt.push(dataRegion);
          this.region_filter_opt.push(dataRegion);
          if (this.arr_region[data[i].region_id] == undefined) {
            this.arr_region[data[i].region_id] == "";
          }
          this.arr_region[data[i].region_id] = data[i].region_description;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listPropinsi(region_id) {
      this.rows = [];
      try {
        const { data } = await metadata.getPropinsiListByRegion(region_id);
        console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            id: data[i].propinsi_id,
            balai: data[i].region_description,
            propinsi_code: data[i].propinsi_code,
            propinsi_name: data[i].propinsi_name,
            path: data[i].path,
          };
          this.rows.push(rowsPropinsi);

          if (this.arr_data_propinsi[data[i].path] == undefined) {
            this.arr_data_propinsi[data[i].path] = "";
          }
          const dataPropisi = {
            id: data[i].propinsi_id,
            balai: data[i].region_description,
            propinsi_code: data[i].propinsi_code,
            propinsi_name: data[i].propinsi_name,
            path: data[i].path,
            balai_id: data[i].region_id,
          };
          this.arr_data_propinsi[data[i].path] = dataPropisi;

          // this.items.push(dataPropinsi);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async listAllPropinsi() {
      this.rows = [];
      try {
        const { data } = await metadata.getPropinsiList();
        console.log(data);
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            id: data[i].propinsi_id,
            balai: data[i].region_description,
            propinsi_code: data[i].propinsi_code,
            propinsi_name: data[i].propinsi_name,
            path: data[i].path,
          };
          this.rows.push(rowsPropinsi);
          // this.items.push(dataPropinsi);
          if (this.arr_data_propinsi[data[i].path] == undefined) {
            this.arr_data_propinsi[data[i].path] = "";
          }
          const dataPropisi = {
            id: data[i].propinsi_id,
            balai: data[i].region_description,
            propinsi_code: data[i].propinsi_code,
            propinsi_name: data[i].propinsi_name,
            path: data[i].path,
            balai_id: data[i].region_id,
          };
          this.arr_data_propinsi[data[i].path] = dataPropisi;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    regions(newValue, oldValue) {
      console.log("old : " + oldValue + " new : " + newValue);
      // this.listPropinsi(newValue)
      this.region_filter = newValue;
    },
    region_filter(value) {
      if (value == "all") {
        this.listAllPropinsi();
      } else if (value != "") {
        this.listPropinsi(value);
      }
    },
    regions_opt() {
      // console.log(regions_opt);
    },
  },
  mounted() {
    this.optionRegion();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
